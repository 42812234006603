<template>
    <div class="account">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    充值
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='type_cont'>
            <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/wechat.png' class='type_img'/>
            账户余额
        </div>

        <div class='charge_cont'>
            <div class='charge_title'>充值金额</div>
            <div class='number_cont'>
                ¥ <van-field type='number' v-model="money" placeholder="请输入金额" clearable class='input_text' size='large'/>
            </div>
        </div>

        <div class='btn_cont mt80'>
            <van-button type="primary" block round color='#00C291' @click='submitRecharge' :disabled='!money'>确认充值</van-button>
        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog,Toast } from 'vant'
import loginApi from "@axios/login";


export default {
  name: 'account',
  components: {


  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()

    let money = ref('')
    const submitRecharge = () => {
        if (money.value <= 0) {
            Toast("请输入充值金额");
            return false;
        }
        if (money.value < 0.01) {
            Toast.open("充值金额不低于0.01元");
            return false;
        }

        let formData = new FormData();
        formData.append(
            "data",
            JSON.stringify({
                function: "recharge",
                source: "h5_users_wx_101",
                user_id: store.state.uid,
                fee:money.value,
                pay_type : '45',
                gate_id : '-1',
                sid : store.state.sid,
                app_flag : '3',
                device_id : 'h5',
                _from : 'h5',
                token : store.state.token,
                userid : store.state.uid,
                userId : store.state.uid,
                version : store.state.version,
                newversion: store.state.newversion,
                openid : store.state.openId
            })
        );
        formData.append("encryption", false);

        loginApi.loginAction(formData).then(res => {
            console.log(res)
            console.log(res.appId)
            let result = JSON.parse(res.data)
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId":result.appId,     //公众号ID，由商户传入
                    "timeStamp":result.timeStamp,         //时间戳，自1970年以来的秒数
                    "nonceStr":result.nonceStr, //随机串
                    "package":result.package,
                    "signType":result.signType,         //微信签名方式：
                    "paySign":result.paySign //微信签名
                },
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        router.push.back()
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        router.push.back()
                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        router.push.back()
                    }
                }
            );
        });
    }


    return {
        submitRecharge,
        money,
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .account{
        height: 100%;
        background: #F2F2F2;
    }
    .type_cont{
        width: 655px;
        padding-left:35px;
        background: #fff;
        padding-top:35px;
        padding-bottom:35px;
        margin-left:30px;
        margin-top:48px;
        border-radius: 20px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        img{
            width: 44px;
            margin-right:36px;
        }
    }
    .charge_cont{
        padding-top:25px;
        padding-left:35px;
        padding-right:25px;
        padding-bottom:46px;
        background: #FFFFFF;
        border-radius: 20px;
        width: 630px;
        margin-left:30px;
        margin-top:30px;
    }
    .charge_title{
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #313131;
    }
    .number_cont{
        display: flex;
        justify-content: flex-start;
        font-size: 44px;
        font-family: Gotham-Bold, Gotham;
        font-weight: bold;
        color: #313131;
        align-items: center;
        margin-top:40px;
    }
    .input_text{
        font-size: 40px;
    }

</style>
